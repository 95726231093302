import _objectSpread from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top";
import list from "./components/list";
import { mapMutations } from "vuex";
import CommonFooter1 from "@/components/common-footer1"; // import axios from "axios";

export default {
  // metaInfo: {
  //   title: "新闻中心_常达智能", // set a title
  //   meta: [
  //     {
  //       // set meta
  //       name: "keyWords",
  //       content: "新闻中心,常达",
  //     },
  //     {
  //       name: "description",
  //       content:
  //         "常达智能物联（深圳）有限公司为全资港资企业，专注于RFID核心技术、拥有自主研发团队，开发RFID系统平台/RFID产品与RFID项目解决方案，为客户提供一站式RFID解决方案和配套软硬件的开发与实施服务。",
  //     },
  //   ],
  // },
  components: {
    CommonFooter1: CommonFooter1,
    top: top,
    list: list
  },
  computed: {},
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite", "setIsNews"])),
  mounted: function mounted() {
    this.setHeaderColorWhite(); // this.setIsNews(true);

    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init(); // this.setIframe();
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack(); // this.setIsNews(false);
  }
};