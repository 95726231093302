import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_top = _resolveComponent("top");

  var _component_list = _resolveComponent("list");

  var _component_common_footer1 = _resolveComponent("common-footer1");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_top), _createVNode(_component_list), _createElementVNode("div", {
    style: _normalizeStyle({
      margin: '1rem 0 .4rem',
      height: '.02rem',
      background: '#E5E6EB'
    })
  }, null, 4), _createVNode(_component_common_footer1)], 64);
}